import axios from "axios";

// ** Get all Data
export const getAllData = () => {
  return async (dispatch, getState) => {
    const state = getState().socket;
    const authData = getState().auth;
    // await axios.get('/api/users/list/all-data').then(response => {
    //   dispatch({
    //     type: 'GET_ALL_DATA',
    //     data: response.data
    //   })
    // })
    const response = await fetch(
      "https://backend.mypagesmm.com/dashboard/notis",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authData?.accessToken || state?.token}`,
        },
      }
    );

    const res = await response.json();
    // console.log(res)
    if (res?.success) {
      dispatch({
        type: "GET_ALL_SOCKET_NOTIS",
        data: res.dashboardNotis,
      });
    } else {
      dispatch({
        type: "GET_ERROR",
        error: res?.msg || res?.message,
      });
    }
  };
};

export const deleteSingleSocket = (id) => {
  // console.log(id)
  return async (dispatch, getState) => {
    let initialSocketNotis = getState().socket.socketNotis;
    const authData = getState().auth;
    const state = getState().socket;
    // console.log(initialSocketNotis)

    const removedDeletedDatas = initialSocketNotis.filter((each) => {
      return each.id !== id;
    });
    // console.log('sssssssssssssssssssssssssssss', removedDeletedDatas)
    dispatch({
      type: "DELETE_SINGLE_SOCKET",
      data: removedDeletedDatas,
    });

    try {
      const respond = await fetch(
        `https://backend.mypagesmm.com/dashboard/notis/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authData?.accessToken || state?.token}`,
          },
        }
      );
      const data = await respond.json();
      if (!data?.success) {
        dispatch({
          type: "GET_ERROR",
          error: data?.msg || data?.message,
        });
      }
    } catch (error) {
      console.log("ssssssssssssssssssssssss", error);
    }
  };

  // .then(res=>res.json())
  // .then((json) => {
  //   console.log('sssssssssssssssssssssssssssss')
  //   return json;
  // dispatch(getData(getState().users.params))
  // dispatch(getAllData())
  //  const removedDeletedDatas = initialBooks.filter((each) => {
  //   return each.id !== id
  // })

  // dispatch({
  //         type: 'DELETE_USER',
  //         data: removedDeletedDatas
  // })
  //     })
  // .catch(e => console.log(e))

  // axios
  //   .delete('/apps/users/delete', { id, initialUsers: getState().users.allData })
  //   .then(response => {
  //     console.log(response)
  //     dispatch({
  //       type: 'DELETE_USER',
  //       data: response.data
  //     })
  //   })
  //   .then(() => {
  //     dispatch(getData(getState().users.params))
  //     dispatch(getAllData())
  //   })
};

export const deleteSingleOrder = (id) => {
  // console.log(id)
  return async (dispatch, getState) => {
    let initialSocketOrders = getState().socket.orders;
    const authData = getState().auth;
    const state = getState().socket;

    const removedDeletedDatas = initialSocketOrders.filter((each) => {
      return each._id !== id;
    });
    console.log("sssssssssssssssssssssssssssss", removedDeletedDatas);
    dispatch({
      type: "DELETE_SINGLE_SOCKET_ORDER",
      data: removedDeletedDatas,
    });

    try {
      const respond = await fetch(
        `https://backend.mypagesmm.com/orders/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authData?.accessToken || state?.token}`,
          },
        }
      );
      const data = await respond.json();
      if (!data?.success) {
        dispatch({
          type: "GET_ERROR",
          error: data?.msg || data?.message,
        });
      }
    } catch (error) {
      console.log("ssssssssssssssssssssssss", error);
    }
  };
};

export const readedAllSocketNotis = () => {
  return async (dispatch, getState) => {
    let initialSocketNotis = getState().socket.socketNotis;
    const authData = getState().auth;
    const state = getState().socket;
    // console.log(initialSocketNotis)

    const readedSocketNotis = initialSocketNotis
      .filter((each) => !each?.read)
      .map((each) => each?.id);

    // console.log('sssssssssssssssssssssssssssss', readedSocketNotis)
    // dispatch({
    //   type: 'DELETE_SINGLE_SOCKET',
    //   data: readedSocketNotis
    // })

    try {
      const respond = await fetch(
        `https://backend.mypagesmm.com/dashboard/notis/read`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authData?.accessToken || state?.token}`,
          },
          body: JSON.stringify({ ids: readedSocketNotis }),
        }
      );
      const data = await respond.json();
      if (!data?.success) {
        dispatch({
          type: "GET_ERROR",
          error: data?.msg || data?.message,
        });
      } else {
        dispatch(getAllData());
      }
    } catch (error) {
      console.log("ssssssssssssssssssssssss", error);
    }
  };
};

export const clearAllSocketNotis = () => {
  return async (dispatch, getState) => {
    const authData = getState().auth;
    const state = getState().socket;

    try {
      const respond = await fetch(
        `https://backend.mypagesmm.com/dashboard/notis`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authData?.accessToken || state?.token}`,
          },
        }
      );
      const data = await respond.json();
      if (!data?.success) {
        dispatch({
          type: "GET_ERROR",
          error: data?.msg || data?.message,
        });
      } else {
        dispatch(getAllData());
      }
    } catch (error) {
      console.log("ssssssssssssssssssssssss", error);
    }
  };
};
