// ** Initial State
const initialState = {
  allData: [],
  allAudios: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedAudio: localStorage.getItem("selectedAudio") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedAudio")),
  bookEdit: false,
  customPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: '',
  // isTokenExpire: false
}

// console.log('sssssssssssss', selectedUser)

const audios = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_AUDIOS':
      return { ...state, allAudios: action.data, customPagination: action.customTotal, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    // case 'TOKEN_EXPIRE':
    //     return {
    //       ...state,
    //       isTokenExpire: true
    //     }
    case 'GET_BOOK_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    
    case 'GET_USER':
      // console.log(action)
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state, allData: action.data }
    case 'DELETE_AUDIO':
      // console.log(action.data)
      return { ...state, allAudios: action.data }
    case 'EDITAUDIO':
      return { ...state, selectedAudio: action.data }
    case 'AUDIO_PAGE_CHANGE':
      return { ...state, allAudios: [] }
    default:
      return { ...state }
  }
}
export default audios
