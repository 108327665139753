// ** Initial State
const initialState = {
  allHistories: [],
  allPurchaseHistories: [],
  customPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: "",
  entries_per_page: "",
  total_results: "",
  latestNotis: [],
};

// console.log('sssssssssssss', selectedUser)

const purchaseHistory = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PURCHASEHISTORIES":
      return {
        ...state,
        allPurchaseHistories: action.data,
        customPagination: action.customTotal,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };
    // case 'GET_ALL_PURCHASE_HISTORY':
    //   return {...state, allPurchaseHistories: [action.data, ...state?.allPurchaseHistories], entries_per_page: 20}
    case "REMOVE_ALL_PURCHASE_HISTORY":
      return { ...state, allPurchaseHistories: [] };
    // case "ADD_NEW_ORDER_HISTORY":
    //   return {
    //     ...state,
    //     allPurchaseHistories: [action.data, ...state?.allPurchaseHistories],
    //   };
    case "ADD_NEW_ORDER_HISTORY":
      // Add the new order history to allPurchaseHistories
      const updatedAllPurchaseHistories = [
        action.data,
        ...state?.allPurchaseHistories,
      ];

      let updatedLatestNoti;

      if (state.latestNotis.length === 10) {
        // Remove from the last place
        updatedLatestNoti = [...state.latestNotis.slice(0, -1)];
      } else {
        updatedLatestNoti = [...state.latestNotis];
      }

      // Add the new order history to the first place
      updatedLatestNoti = [action.data, ...updatedLatestNoti];

      return {
        ...state,
        allPurchaseHistories: updatedAllPurchaseHistories,
        latestNotis: updatedLatestNoti,
      };
    case "GET_LATEST_ORDERS":
      return { ...state, latestNotis: action.data };
    case "PURCHASE_HISTORY_TOTAL":
      return { ...state, total_results: action.data };
    default:
      return { ...state };
  }
};
export default purchaseHistory;
