// ** Initial State
const initialState = {
  allData: [],
  allBooks: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedBook: localStorage.getItem("selectedBook") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedBook")),
  bookEdit: false,
  customPagination: null,
  addonBooks: [],
  addonBookPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: '',
  uploading: false,
  isTokenExpire: false,
}

// console.log('sssssssssssss', selectedUser)

const books = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_BOOKS':
      return { ...state, allBooks: action.data, customPagination: action.customTotal, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'GET_ALL_SPECIFIC_BOOKS':
      return { ...state, addonBooks: action.data, addonBookPagination: action.customTotal, found_results: action?.found_results, total_results: action?.total_results }
    case 'GET_BOOK_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'TOKEN_EXPIRE':
      console.log("heloo")
      return {
        ...state,
        isTokenExpire: true
      }
    case 'GET_USER':
      // console.log(action)
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state, allData: action.data }
    case 'DELETE_BOOK':
      // console.log(action.data)
      return { ...state, allBooks: action.data }
    case 'EDITBOOK':
      return { ...state, selectedBook: action.data }
    case 'BOOK_PAGE_CHANGE':
      return { ...state, allBooks: [], addonBooks: [] }
    case 'ADDON_CHANGE':
      return { ...state, allBooks: [], addonBooks: [] }
    // case 'START_LOADING':
    //   return {...state, loading: true}
    // case 'END_LOADING':
    //   return {...state, loading: false}
    case "STARTUPLOADING":
      return { ...state, uploading: true }
    case "ENDUPLOADING":
      return { ...state, uploading: false }
    default:
      return { ...state }
  }
}
export default books
