// ** Initial State
const initialState = {
  allData: [],
  allPackageSubscribers: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedBook: null,
  bookEdit: false,
  customPagination: null,
  currentSelectedPackageId: null,
  openModel: false,
  notifyId: null,
  allSelectedPackageUsersForNoti: [],
  allSelectedPackageUserNamesForNoti: [],
  singleNotiUser: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: '',
  confirmModal: false,
  cancelUser: {}
}

// console.log('sssssssssssss', selectedUser)

const packageSubscriptions = (state = initialState, action) => {
  console.log(action.data)
  switch (action.type) {
    case 'GET_ALL_PACKAGESUBSCRBERS':

      return { ...state, allPackageSubscribers: action.data, customPagination: action.customTotal, currentSelectedPackageId: action.currentSelectedPackageId, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'NOTIFYID':
      return { ...state, notifyId: action.id, openModel: true, singleNotiUser: action.name }
    case 'SELECTED_PACKAGE_USER_FOR_NOTI':
      // console.log(action.names);
      return { ...state, allSelectedPackageUsersForNoti: action.payload, allSelectedPackageUserNamesForNoti: action.names }
    case 'CLOSE_NOTI_MODEL':
      return { ...state, openModel: false, singleNotiUser: null }
    case 'SUBMIT_CLOSE_NOTI_MODEL_ADDON':
      return { ...state, openModel: false, allSelectedPackageUsersForNoti: [], allSelectedPackageUserNamesForNoti: [] }
    case 'OPEN_NOTI_MODEL':
      return { ...state, openModel: true }
    case 'OPEN_CONFIMR_MODEL':
      return {...state, confirmModal: true, cancelUser: action.data}
    case 'CLOSE_CONFIRM_MODEL':
      return {...state, confirmModal: false}
    case 'UNSELECT_PACKAGE_USER_FOR_NOTI':
      return {...state, allSelectedPackageUsersForNoti: [], allSelectedPackageUserNamesForNoti: []}
    default:
      return { ...state }
  }
}
export default packageSubscriptions
