// ** Initial State
const initialState = {
  audio: {},
  audioChapters: [],
  allAudios: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedAudioChapter: localStorage.getItem("selectedAudioChapter") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedAudioChapter")),
  bookEdit: false,
  customPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: '',
}

// console.log('sssssssssssss', selectedUser)

const audioChapters = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SPECIFIC_AUDIO_FOR_CHAPTER':
      return {...state, audio: action?.data, audioChapters: action?.data?.chapters}
    case 'DELETE_AUDIO_CHAPTER':
      return {...state, audioChapters: action?.data}
    case 'EDITAUDIOCHAPTER':
      return {...state, selectedAudioChapter: action?.date}
    
    case 'AUDIO_PAGE_CHANGE':
      return { ...state, allAudios: [] }
    default:
      return { ...state }
  }
}
export default audioChapters
