// ** Initial State
const initialState = {
  allMostReadedBooks: [],
  customPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: ''

}

// console.log('sssssssssssss', selectedUser)

const bookLog = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_MOSTREADBOOKS':
      return { ...state, allMostReadedBooks: action.data, customPagination: action.customTotal, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'DELETE_HISTORY':
      // console.log(action.data)
      return { ...state, allMostReadedBooks: action.data }
    case 'CLEAR_HISTORIES':
      return { ...state, allMostReadedBooks: [] }
    default:
      return { ...state }
  }
}
export default bookLog
