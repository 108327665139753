// ** Initial State
const initialState = {
  allData: [],
  allAddonSubscribers: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedBook: null,
  bookEdit: false,
  customPagination: null,
  currentSelectedAddonId: null,
  openAddonNotiModel: false,
  notifyId: null,
  notifyUser: null,
  allSelectedAddonUsersForNoti: [],
  allSelectedAddonUserNamesForNoti: [],
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: '',
  confirmModal: false,
  cancelUser: {}
}

// console.log('sssssssssssss', selectedUser)

const addonSubscriptions = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ADDONSUBSCRBERS':
      // console.log(action.data)
      return { ...state, allAddonSubscribers: action.data, customPagination: action.customTotal, currentSelectedAddonId: action.currentSelectedAddonId, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'NOTIFYADDONID':
      return { ...state, openAddonNotiModel: true, notifyId: action.id, notifyUser: action.notifyUser }
    case 'CANCELADDONUSER':
      return { ...state, allAddonSubscribers: action.data }
    case 'SELECTED_ADDON_USER_FOR_NOTI':
      // console.log(action.names);
      return { ...state, allSelectedAddonUsersForNoti: action.payload, allSelectedAddonUserNamesForNoti: action.names }
    case 'CLOSE_NOTI_ADDON_MODEL_ADDON':
      return { ...state, openAddonNotiModel: false }
    case 'SUBMIT_CLOSE_NOTI_ADDON_MODEL_ADDON':
      return { ...state, openAddonNotiModel: false, allSelectedAddonUsersForNoti: [], allSelectedAddonUserNamesForNoti: [] }
    case 'OPEN_ADDDON_NOTI_MODEL':
      return { ...state, openAddonNotiModel: true }
    case 'OPEN_CONFIMR_MODEL_ADDON':
      // return { ...state, confirmModal: true }
      return {...state, confirmModal: true, cancelUser: action.data}
    case 'CLOSE_CONFIRM_MODEL':
      return { ...state, confirmModal: false }
    case 'UNSELECT_ADDDON_USER_FOR_NOTI':
      return {...state, allSelectedAddonUsersForNoti: [], allSelectedAddonUserNamesForNoti: []}
    default:
      return { ...state }
  }
}
export default addonSubscriptions
