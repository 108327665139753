// ** Initial State
const initialState = {
  allData: [],
  allAddons: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedAudio: null,
  bookEdit: false,
  customPagination: null,
  selectedAddon: null,
  openAddonModel: false,
  token: JSON.parse(localStorage.getItem("accessToken"))
}

// console.log('sssssssssssss', selectedUser)

const addonsPurchase = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_BOOKS':
      return { ...state, allAddons: action.data, customPagination: action.customTotal }
    case 'GET_BOOK_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      // console.log(action)
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state, allData: action.data }
    case 'DELETE_ADDON':
      // console.log(action.data)
      return { ...state, allAddons: action.data }
    case 'EDITAUDIO':
      return { ...state, selectedAudio: action.data }
    case 'ADDON_PAGE_CHANGE':
      return { ...state, allAddons: [] }
    case 'ADDON_PURCHASE':
      return { ...state, selectedAddon: action.data, openAddonModel: true }
    case 'CLOSE_ADDON_MODEL':
      return { ...state, openAddonModel: false }
    // case 'START_LOADING':
    //   return {...state, loading: true}
    // case 'END_LOADING':
    //   return {...state, loading: false}
    default:
      return { ...state }
  }
}
export default addonsPurchase
