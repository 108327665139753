import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllData } from '../socket.io/store/action';

// const firebaseConfig = {
//   apiKey: "AIzaSyBSQwRtXiSGxDKKMsSww4eCAD5biYZpmmM",
//   authDomain: "aptm-e363c.firebaseapp.com",
//   databaseURL: "https://aptm-e363c-default-rtdb.firebaseio.com",
//   projectId: "aptm-e363c",
//   storageBucket: "aptm-e363c.appspot.com",
//   messagingSenderId: "779487278709",
//   appId: "1:779487278709:web:4b41bfb0f7871af324aa3c",
//   measurementId: "G-Y9MV1N77GW"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyCQoB-xBiLFNYmpkvnkU-ordkfL2ZXHbTM",
//   authDomain: "pushnotittwo.firebaseapp.com",
//   databaseURL: "https://pushnotittwo-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "pushnotittwo",
//   storageBucket: "pushnotittwo.appspot.com",
//   messagingSenderId: "242936411485",
//   appId: "1:242936411485:web:032e5f2eaea3c7aa5c64b4",
//   measurementId: "G-GP4P0SQBDT"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBSQwRtXiSGxDKKMsSww4eCAD5biYZpmmM",
  authDomain: "aptm-e363c.firebaseapp.com",
  databaseURL: "https://aptm-e363c-default-rtdb.firebaseio.com",
  projectId: "aptm-e363c",
  storageBucket: "aptm-e363c.appspot.com",
  messagingSenderId: "779487278709",
  appId: "1:779487278709:web:4b41bfb0f7871af324aa3c",
  measurementId: "G-Y9MV1N77GW"
};

firebase.initializeApp(firebaseConfig);

export default function EventFirebase() {
  const store = useSelector(state => state.socket)
  const dispatch = useDispatch();

  console.log(store?.orders)

  // useEffect(() => {
  //   const connectedRef = firebase.database().ref('.info/connected');
  //   const dbRef = firebase.database().ref('notifications');
  //   const orderRef = firebase.database().ref('orders');
  //   const orderInitialRef = orderRef.child("initial");

  //   connectedRef.on('value', (snapshot) => {
  //     const isConnected = snapshot.val();
  //     console.log('Firebase Realtime Database connection state:', isConnected);
  //     if (isConnected) {
  //       // dbRef.once('value', (snapshot) => {
  //       //   const usersData = snapshot.val();
  //       //   console.log('Users data:', usersData);
  //       //   dispatch({
  //       //     type: 'GET_ALL_SOCKET_NOTIS',
  //       //     data: usersData
  //       //   })
  //       // });
  //       dbRef.on('child_added', (snapshot) => {
  //         // console.log(snapshot)
  //         // Retrieve the user data from the child snapshot
  //         const data = { id: snapshot.key, ...snapshot.val() };
  //         // console.log("hello data", data)
  //         console.log('databaseData', data)
  //         // Update the state with the new user data
  //         // setUsers((prevUsers) => [...prevUsers, users]);
  //         dispatch({
  //           type: 'ADD_NEW_NOTIS',
  //           data: data
  //         })
  //       });

  //       orderRef.on('child_added', (snapshot) => {
  //         console.log("hello order data", snapshot)
  //         const data = { id: snapshot.key, ...snapshot.val() };
  //         console.log('databaseData2', data)
  //         // dispatch({
  //         //   type: 'ADD_NEW_NOTIS',
  //         //   data: data
  //         // })
  //       });
  //     }
  //   });
  // }, [])


  useEffect(() => {
    const connectedRef = firebase.database().ref('.info/connected');
    const dbRef = firebase.database().ref('notifications');
    const orderRef = firebase.database().ref('orders');
    const orderInitialRef = orderRef.child("initial");
    const orderAssignedRef = orderRef.child('assigned');

    
  
    connectedRef.on('value', (snapshot) => {
      const isConnected = snapshot.val();
      console.log('Firebase Realtime Database connection state:', isConnected);
      if (isConnected) {
        // Listen for changes in 'notifications'
        dbRef.on('child_added', (snapshot) => {
          const data = { id: snapshot.key, ...snapshot.val() };
          // console.log('databaseData', data);
          dispatch({
            type: 'ADD_NEW_NOTIS',
            data: data
          });
        });
  
        // Listen for changes in 'orders'
        // orderInitialRef.on('child_added', (snapshot) => {
        //   const data = { id: snapshot.key, ...snapshot.val() };
        //   // console.log('databaseData2', data);
        //   // Dispatch or handle data as needed
          
        //   dispatch({
        //     type: 'ADD_NEW_ORDER',
        //     data: data
        //   });
        // });

        // orderInitialRef.on('child_removed', (snapshot) => {
        //   const removedOrderId = snapshot.key;
        //   // console.log('databaseData2 - removed', removedOrderId);
        //   // Dispatch or handle removal as needed

        //   // store?.order
        //   // console.log(store?.orders)
        //   // console.log(store?.orders?.filter(order => order.id !== removedOrderId))
          

        //   orderInitialRef.once('value', (snapshot) => {
        //     const remainingData = snapshot.val();
        //     // console.log('Remaining Data:', remainingData);
        //     // console.log(typeof remainingData);

        //     const dataArray = Object.values(remainingData);

        //     // Now, dataArray is an array of objects
        //     // console.log(dataArray);

        //     if(!remainingData) {
        //       dispatch({
        //       type: 'REMOVE_ORDER',
        //       data: []
        //     });
        //     } else {
        //       dispatch({
        //         type: 'REMOVE_ORDER',
        //         data: dataArray
        //       });
        //     }
            
        // });
        //   // dispatch({
        //   //   type: 'REMOVE_ORDER',
        //   //   orderId: removedOrderId
        //   // });
        // });

       
        // orderAssignedRef.on('child_added', (snapshot) => {
        //   const data = { id: snapshot.key, ...snapshot.val() };
        //   // Dispatch or handle data as needed
        //   // console.log('data3',data)
          
        //   dispatch({
        //     type: 'GET_ALL_PURCHASE_HISTORY',
        //     data: data
        //   });
        // });

      }
    });
  
    // Cleanup the listeners when the component unmounts
    return () => {
      connectedRef.off();
      dbRef.off();
      orderRef.off();
    };
  }, []);
  

  useEffect(() => {

  },[])

  // useEffect(() => {
  //     dispatch(getAllData())
  // },[])

  return (
    <div></div>
  );
}


